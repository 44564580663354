import styled from "@emotion/styled";
import React from "react";
import { BiometricVerificationBloc } from "src/hybrid/components/BiometricVerification";
import { useBloc } from "src/state/state";
import type { TextForAuthTypes } from "../GlobalOverlays/Overlays/BiometricSetup";
import { textForAuthTypes } from "../GlobalOverlays/Overlays/BiometricSetup";
import LockIcon from "src/ui/assets/icons/LockIcon";
import Data from "../Data/Data";
import translate from "src/lib/translate";

const Wrap = styled.div`
  padding: 1rem 1rem 1.5rem;
`;

const BiometricAuthenticationSetup = () => {
  const [
    { biometricType, isBiometricVerificationEnabled },
    { setupBiometricVerification, switchToFallbackCredentialProvider, enabled }
  ] = useBloc(BiometricVerificationBloc);

  const text: TextForAuthTypes =
    textForAuthTypes[biometricType] ??
    ({
      name: "biometric",
      icon: <LockIcon />,
      title: "signInQuicker",
      description: "biometric.description",
      button: "enable_signIn"
    } satisfies TextForAuthTypes);

  if (!enabled) {
    return null;
  }

  return (
    <Data.Table title={translate(text.name)}>
      <Wrap>
        {isBiometricVerificationEnabled ? (
          <>
            <p>{translate(text.description)}</p>
            <nine-button
              variant="outline"
              color="black"
              padding="equal"
              size="sm"
              onClick={(): void => void switchToFallbackCredentialProvider()}
            >
              {translate("disable.feature", {
                context: "biometricAuth",
                feature: translate(text.name)
              })}
            </nine-button>
          </>
        ) : (
          <>
            <p>{translate(text.description)}</p>
            <nine-button
              variant="outline"
              color="black"
              padding="equal"
              size="sm"
              onClick={(): void => void setupBiometricVerification()}
            >
              {translate("enable.feature", {
                context: "biometricAuth",
                feature: translate(text.name)
              })}
            </nine-button>
          </>
        )}
      </Wrap>
    </Data.Table>
  );
};

export default BiometricAuthenticationSetup;
