import styled from "@emotion/styled";
import type { FC, ReactNode } from "react";
import React from "react";
import { toast } from "src/state/state";

const CopyTrigger = styled.button`
  padding: 0.5em 1em 0.35em;
  border: 1px solid #00000033;
  border-radius: 0.3em;
  background-color: #00000011;
  display: block;
  margin: 0.5em 0 0;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 0;
  font-family: monospace;
`;

const CopyText: FC<{ text: string; children?: ReactNode }> = ({
  text,
  children
}) => {
  const copyText = () => {
    void navigator.clipboard.writeText(text).then(() => {
      toast.show("invite_copy_success");
    });
  };

  return <CopyTrigger onClick={copyText}>{children}</CopyTrigger>;
};

export default CopyText;
