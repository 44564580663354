import type { FC } from "react";
import React from "react";
import { Navigate } from "react-router-dom";
import Path from "src/constants/path";
import { createAppPath } from "src/lib/createAppPath";
import { TrackEvent, TrackType } from "src/state/Track/TrackCubit";
import SendToast from "src/ui/components/SendToast/SendToast";
import Track from "src/ui/components/Track/Track";

const SuccessChangedEmail: FC = () => {
  return (
    <div>
      <Track event={TrackEvent.changeEmail} type={TrackType.complete} />
      <SendToast message="email_change_success" />
      <Navigate to={createAppPath(Path.profile)} replace />
    </div>
  );
};

export default SuccessChangedEmail;
