import type { ApiError } from "@9amhealth/openapi";
import { UserMfaControllerService } from "@9amhealth/openapi";
import { Cubit } from "blac";
import reportErrorSentry from "src/lib/reportErrorSentry";
import translate from "src/lib/translate";
import { toast, userState } from "src/state/state";

interface TwoFactorAuthenticationState {
  enabled: boolean;
  setupStep: "disable" | "error" | "start" | "verify";
  loading: boolean;
  error?: string;
  secret?: string;
}

export default class TwoFactorAuthenticationBloc extends Cubit<TwoFactorAuthenticationState> {
  constructor() {
    super({ enabled: false, setupStep: "start", loading: true });
  }

  checkUser = async () => {
    const enabled = userState.state.userData?.mfaActive ?? false;
    this.emit({ ...this.state, enabled, loading: false });
  };

  resetError = () => {
    this.emit({ ...this.state, error: undefined });
  };

  startSetup = () => {
    this.emit({ ...this.state, setupStep: "verify" });
    void this.getNewSecret();
  };

  getNewSecret = async () => {
    this.emit({ ...this.state, loading: true, error: undefined });
    let { secret, error, setupStep } = this.state;
    try {
      const { data } =
        await UserMfaControllerService.retrieveCredential("RFC6238");

      // eslint-disable-next-line @typescript-eslint/prefer-destructuring
      secret = data.secret;
    } catch (e) {
      reportErrorSentry(e);
      const eTyped = e as ApiError;
      error = eTyped.message;
      setupStep = "error";
    }

    this.emit({ ...this.state, loading: false, secret, error, setupStep });
  };

  activateSecret = async (token?: string) => {
    if (!token) {
      return;
    }

    this.emit({ ...this.state, loading: true, error: undefined });
    let { enabled, error, setupStep } = this.state;

    try {
      await UserMfaControllerService.activateCredential({
        token
      });
      toast.success("mfa.enabled.successfully");
      enabled = true;
      setupStep = "start";
    } catch (e) {
      reportErrorSentry(e);
      const eTyped = e as Partial<ApiError>;
      error = eTyped.message ?? translate("mfa.enabled.code_error");
    }

    this.emit({ ...this.state, enabled, loading: false, error, setupStep });
  };

  removeCredential = async (token?: string) => {
    if (!token) return;
    this.emit({ ...this.state, loading: true, error: undefined });
    let { enabled, error, setupStep } = this.state;

    try {
      await UserMfaControllerService.removeCredential({
        token
      });
      toast.show("mfa.disabled");
      enabled = false;
      setupStep = "start";
    } catch (e) {
      reportErrorSentry(e);
      const eTyped = e as ApiError;
      error = eTyped.message;
    }

    this.emit({ ...this.state, enabled, loading: false, error, setupStep });
  };

  startDisable = () => {
    this.emit({ ...this.state, setupStep: "disable" });
  };
}

export const TwoFactorAuthenticationController =
  new TwoFactorAuthenticationBloc();
