import type { CSSProperties, FC } from "react";
import React, { useState } from "react";
import { validatePhoneNumber } from "src/lib/formatPhoneNumber";
import { TrackEvent, TrackType } from "src/state/Track/TrackCubit";
import { toast } from "src/state/state";
import Loader from "src/ui/components/Loader/Loader";
import OnEvent from "src/ui/components/OnEvent/OnEvent";
import Track from "src/ui/components/Track/Track";
import Translate from "src/ui/components/Translate/Translate";
import FormError from "../FormError/FormError";

import { useNavigate } from "react-router-dom";
import { APP_CONTENT_WIDTH_WITHOUT_PADDING } from "src/constants/layout";
import Path from "src/constants/path";
import { createAppPath } from "src/lib/createAppPath";
import translate from "src/lib/translate";
import UserCubit from "src/state/UserCubit/UserCubit";
import type { TranslationKey } from "src/types/translationKey";

interface ResetFormValues {
  phoneNumber: string;
}

const ChangeNotificationNumber: FC = () => {
  const [errorCode, setErrorCode] = useState<TranslationKey>();
  const navigate = useNavigate();

  const handleSubmit = (data: CustomEvent<ResetFormValues>) => {
    setErrorCode(undefined);
    const { phoneNumber } = data.detail;

    if (!phoneNumber) {
      return setErrorCode("invalid_phone_number");
    }

    const isValid = validatePhoneNumber(phoneNumber, "US");

    if (!isValid) {
      return setErrorCode("invalid_phone_number");
    }

    void UserCubit.setNotificationNumber(phoneNumber)
      .then(() => {
        navigate(createAppPath(Path.profile), { replace: true });
        toast.success("phoneNumberUpdated");
      })
      .catch(() => {
        setErrorCode("error_generic");
      });
  };

  return (
    <div
      style={
        {
          "--section-max-width": `${APP_CONTENT_WIDTH_WITHOUT_PADDING}px`
        } as CSSProperties
      }
    >
      <Track event={TrackEvent.changeNumber} type={TrackType.start} />

      <nine-spacer s="xl"></nine-spacer>
      <nine-heading>
        <h3 className="as-h4-large">
          <Translate msg="setPhoneNumber" />
        </h3>
      </nine-heading>

      <nine-spacer s="xl"></nine-spacer>
      <Loader fixed background />

      <OnEvent
        events={{
          submit: handleSubmit,
          change: () => setErrorCode(undefined)
        }}
      >
        <nine-form>
          <nine-input
            scroll-into-view-on-focus
            name={"phoneNumber"}
            label={translate("phoneNumber")}
            mask="(000) 000-0000"
            lazy="false"
            type="tel"
          ></nine-input>

          <FormError
            data-testid="form-error"
            msg={errorCode}
            code={errorCode}
            spaceAfter={false}
          />

          <nine-content>
            <div className="center">
              <nine-spacer s="md"></nine-spacer>
              <nine-button type="submit">
                <Translate msg="save" />
              </nine-button>
            </div>
          </nine-content>
        </nine-form>
      </OnEvent>
    </div>
  );
};

export default ChangeNotificationNumber;
