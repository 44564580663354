import { ApiError, ChangePasswordRequest } from "@9amhealth/openapi";
import { AutoForm, AutoFormInputField, Button } from "@9amhealth/shared";
import { Cubit } from "blac";
import type { CSSProperties, FC } from "react";
import React from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { CheckmarkIcon, EyeClosedIcon, EyeIcon } from "src/constants/icons";
import { APP_CONTENT_WIDTH_WITHOUT_PADDING } from "src/constants/layout";
import Path from "src/constants/path";
import { createAppPath } from "src/lib/createAppPath";
import translate from "src/lib/translate";
import { TrackEvent, TrackType } from "src/state/Track/TrackCubit";
import UserCubit from "src/state/UserCubit/UserCubit";
import { authenticationState, toast, tracker, useBloc } from "src/state/state";
import Loader from "src/ui/components/Loader/Loader";
import Track from "src/ui/components/Track/Track";
import Translate from "src/ui/components/Translate/Translate";
import { z } from "zod";
import {
  ButtonWrap,
  PasswordInputWrapper
} from "../ChangeEmail/EmailPasswordForm";
import FormError from "../FormError/FormError";
import { InputFocusVirtualKeyboardPadding } from "../StyledComponents/InputFocusVirtualKeyboardPadding";

export class ChangePasswordBloc extends Cubit<{ errorCode?: string }> {
  navigate: NavigateFunction;

  constructor(navigate: NavigateFunction) {
    super({});
    this.navigate = navigate;
  }

  changeErrorCode = (code: string) => {
    this.emit({ errorCode: code });
  };

  handleSubmit = async (data: {
    newPassword: string;
    oldPassword?: string;
  }): Promise<void> => {
    let error;
    try {
      await authenticationState.changePassword({
        newPassword: data.newPassword,
        currentPassword: data.oldPassword
      } as ChangePasswordRequest);
    } catch (e) {
      error = e;
    }

    if (error) {
      this.emit({ errorCode: (error as ApiError).body?.code });
    } else {
      tracker.track(TrackEvent.changePassword, {
        type: TrackType.complete
      });
      toast.success("password_change_success");
      this.navigate(createAppPath(Path.profile), { replace: true });
    }
  };
}

const Schema = z.object({
  email: z.string().email(),
  oldPassword: z.string({
    required_error: translate("error_validation_required")
  }),
  newPassword: z
    .string({
      required_error: translate("error_validation_required")
    })
    .min(12, { message: "" })
});

const ChangePassword: FC = () => {
  const [seeOldPassword, setSeeOldPassword] = React.useState(false);
  const [seeNewPassword, setSeeNewPassword] = React.useState(false);
  const [showCheckmarkIcon, setShowCheckmarkIcon] = React.useState(false);
  const navigate = useNavigate();

  const [state, { handleSubmit, changeErrorCode }] = useBloc(
    ChangePasswordBloc,
    {
      create: () => new ChangePasswordBloc(navigate)
    }
  );

  const handlePasswordToggleClick = (name: "old" | "new") => {
    if (name === "old") {
      setSeeOldPassword(!seeOldPassword);
    } else {
      setSeeNewPassword(!seeNewPassword);
    }
  };

  const [{ userData }] = useBloc(UserCubit, {
    subscribe: false
  });

  function handleInputChange(value: string) {
    if (value.length >= 12) {
      setShowCheckmarkIcon(true);
    } else {
      setShowCheckmarkIcon(false);
    }
  }

  return (
    <InputFocusVirtualKeyboardPadding
      style={
        {
          "--section-max-width": `${APP_CONTENT_WIDTH_WITHOUT_PADDING}px`
        } as CSSProperties
      }
    >
      <Loader fixed background />
      <Track event={TrackEvent.changePassword} type={TrackType.start} />

      <nine-spacer s="xl"></nine-spacer>
      <nine-heading>
        <h3 className="as-h4-large">
          <Translate msg="setNewPassword" />
        </h3>
      </nine-heading>

      <nine-spacer s="xl"></nine-spacer>

      <AutoForm
        schema={Schema}
        onChange={() => changeErrorCode("")}
        onSubmit={(data) => void handleSubmit(data)}
        initialValue={{ email: userData?.email }}
      >
        <nine-spacer s="sm"></nine-spacer>
        <AutoFormInputField
          name="email"
          label={translate("emailAddress")}
          type="email"
          isDisabled={true}
          autoComplete="username"
          data-testid="email"
          style={{ display: "none" }}
        />
        <PasswordInputWrapper>
          <AutoFormInputField
            name="oldPassword"
            label={translate("password.old")}
            type={seeOldPassword ? "text" : "password"}
            required={true}
            data-testid="old-Password"
          />
          <ButtonWrap
            tabIndex={-1}
            type="button"
            data-testid="old-Password"
            aria-label={
              seeOldPassword
                ? translate("hidePassword")
                : translate("showPassword")
            }
            onClick={() => handlePasswordToggleClick("old")}
          >
            {seeOldPassword ? <EyeClosedIcon /> : <EyeIcon />}
          </ButtonWrap>
        </PasswordInputWrapper>
        <nine-spacer s="md"></nine-spacer>
        <PasswordInputWrapper>
          <AutoFormInputField
            name="newPassword"
            label={translate("password.new")}
            type={seeNewPassword ? "text" : "password"}
            onChange={(value: string) => handleInputChange(value)}
            required={true}
            description={`${!showCheckmarkIcon ? "• " : ""} ${translate("invalid_password")}`}
            DescriptionIcon={showCheckmarkIcon ? CheckmarkIcon : undefined}
          />
          <ButtonWrap
            tabIndex={-1}
            type="button"
            aria-label={
              seeNewPassword
                ? translate("hidePassword")
                : translate("showPassword")
            }
            onClick={() => handlePasswordToggleClick("new")}
          >
            {seeNewPassword ? <EyeClosedIcon /> : <EyeIcon />}
          </ButtonWrap>
        </PasswordInputWrapper>
        <nine-spacer></nine-spacer>

        <FormError
          code={state.errorCode}
          msg="error_password_reset_code"
          data-testid="form-error"
        />
        <Button type="submit" theme="sunrise" style={{ margin: "auto" }}>
          <Translate msg={"confirm"} />
        </Button>
      </AutoForm>
    </InputFocusVirtualKeyboardPadding>
  );
};

export default ChangePassword;
