import { NineButton, NineForm, NineInput } from "@9amhealth/wcl/generated/react";
import styled from "@emotion/styled";
import React, { useEffect } from "react";
import translate from "src/lib/translate";
import { useBloc } from "src/state/state";
import AsyncContent from "../AsyncContent/AsyncContent";
import CopyText from "../CopyText/CopyText";
import Qrcode from "../Qrcode/Qrcode";
import Translate from "../Translate/Translate";
import TwoFactorAuthenticationBloc, {
  TwoFactorAuthenticationController
} from "./TwoFactorAuthenticationBloc";

const MfaContent = styled.div`
  padding: 0.2rem 1rem 1.2rem;

  h5 {
    margin-bottom: 0.8rem;
  }

  .qr-code {
    width: 60%;
    margin: 0 0 1.5rem;
  }

  hr {
    margin: 2rem 0;
  }
`;

const Actions = styled.div`
  width: 100%;
  display: flex;
  margin: 0.5rem 0 0.6rem;
`;

const ErrorContent = styled.div`
  h5 {
    margin-bottom: 0.8rem;
    color: var(--color-danger);
    font-size: 1.6rem;
    font-family: var(--font-family-serif);
  }

  hr {
    margin: 2rem 0;
  }
`;

const TwoFactorAuthenticationSetup = () => {
  const [
    { setupStep, enabled, secret, loading, error },
    {
      startSetup,
      activateSecret,
      resetError,
      startDisable,
      removeCredential,
      checkUser
    }
  ] = useBloc(TwoFactorAuthenticationBloc, {
    create: () => TwoFactorAuthenticationController
  });

  const handleActivateSubmit = (e: CustomEvent<{ token?: string }>) => {
    const { token } = e.detail;
    if (!loading) {
      void activateSecret(token);
    }
  };

  const handleDisableSubmit = (e: CustomEvent<{ token?: string }>) => {
    const { token } = e.detail;
    if (!loading) {
      void removeCredential(token);
    }
  };

  useEffect(() => {
    void checkUser();
  }, []);

  return (
    <MfaContent>
      {enabled && (
        <>
          {setupStep === "start" && (
            <>
              {loading ? (
                <AsyncContent
                  check={[false]}
                  ratio={2}
                  style={{ marginTop: "1.4rem" }}
                />
              ) : (
                <>
                  <h5>
                    <Translate msg="mfa.enabled" />
                  </h5>
                  <Actions>
                    <NineButton
                      type="submit"
                      variant="outline"
                      color="black"
                      breakText={true}
                      size="sm"
                      onClick={() => startDisable()}
                    >
                      <Translate msg="mfa.disable" />
                    </NineButton>
                  </Actions>
                </>
              )}
            </>
          )}

          {setupStep === "disable" && (
            <>
              <h5>
                <Translate msg="mfa.verificationTitle" />
              </h5>
              <p>
                <Translate msg="mfa.verificationCode" />
              </p>
              <NineForm onNineSubmit={handleDisableSubmit}>
                <NineInput
                  label={translate("code")}
                  required="true"
                  mask="000000"
                  lazy="false"
                  name="token"
                  autocomplete="one-time-code"
                  onNineChange={() => (error ? resetError() : "")}
                  forceError={error}
                />
                <Actions>
                  <NineButton
                    type="submit"
                    disabled={loading ? "true" : "false"}
                    variant="outline"
                    color="black"
                    size="sm"
                    breakText={true}
                  >
                    <Translate msg="mfa.disable" />
                  </NineButton>
                </Actions>
              </NineForm>
            </>
          )}
        </>
      )}
      {!enabled && (
        <>
          {setupStep === "start" && (
            <>
              {loading ? (
                <AsyncContent
                  check={[false]}
                  ratio={2}
                  style={{ marginTop: "1.4rem" }}
                />
              ) : (
                <>
                  <h5>
                    <Translate msg="mfa" />
                  </h5>
                  <p>
                    <Translate msg="mfa.description" />
                  </p>
                  <Actions>
                    <NineButton
                      onClick={startSetup}
                      variant="outline"
                      color="black"
                      size="sm"
                      breakText={true}
                    >
                      <Translate msg="mfa.enable" />
                    </NineButton>
                  </Actions>
                </>
              )}
            </>
          )}
          {setupStep === "error" && (
            <ErrorContent>
              <h5>
                <Translate msg="error.generic.short" />
              </h5>
              <p>
                <Translate msg="mfa.error" />
              </p>
              <Actions>
                <NineButton
                  onClick={startSetup}
                  variant="outline"
                  color="black"
                  size="sm"
                >
                  <Translate msg="retry" />
                </NineButton>
              </Actions>
            </ErrorContent>
          )}
          {setupStep === "verify" && (
            <>
              <h5>
                <Translate msg="mfa.scanQR" />
              </h5>
              <p>
                <Translate msg="mfa.scanQRHelper" />
              </p>
              <AsyncContent
                check={[secret]}
                width="calc(60% + 1.5rem)"
                ratio={1}
              >
                {secret && (
                  <Qrcode
                    text={`otpauth://totp/App?secret=${secret}&issuer=9amHealth`}
                    hideLogo
                    frame
                  />
                )}
              </AsyncContent>
              <p>
                <Translate msg="mfa.manualVerification" />
                <CopyText text={secret ?? ""}>
                  {secret ?? ".........."}
                </CopyText>
              </p>
              <hr />
              <h5>
                <Translate msg="mfa.verificationTitle" />
              </h5>
              <p>
                {" "}
                <Translate msg="mfa.verificationCode" />
              </p>
              <NineForm onNineSubmit={handleActivateSubmit}>
                <NineInput
                  label={translate("code")}
                  required="true"
                  mask="000000"
                  autocomplete="one-time-code"
                  lazy="false"
                  name="token"
                  onNineChange={() => (error ? resetError() : "")}
                  forceError={error}
                />
                <Actions>
                  <NineButton
                    type="submit"
                    disabled={loading ? "true" : "false"}
                    variant="outline"
                    color="black"
                    size="sm"
                  >
                    <Translate msg="continue" />
                  </NineButton>
                </Actions>
              </NineForm>
            </>
          )}
        </>
      )}
    </MfaContent>
  );
};

export default TwoFactorAuthenticationSetup;
