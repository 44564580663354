import type { FC } from "react";
import { useEffect } from "react";
import { toast } from "src/state/state";
import type { TranslationKey } from "src/types/translationKey";

const SendToast: FC<{
  message: TranslationKey;
}> = (props) => {
  useEffect(() => {
    toast.show(props.message);
  }, []);

  return null;
};

export default SendToast;
