import type { FC } from "react";
import React, { useEffect } from "react";
import type { VerifyEmailFormValues } from "src/ui/components/ChangeEmail/ChangeEmail";
import FormError from "src/ui/components/FormError/FormError";
import Link from "src/ui/components/Link/Link";
import Translate from "../Translate/Translate";
import translate from "src/lib/translate";
import { AutoForm, AutoFormInputField, Button } from "@9amhealth/shared";
import { z } from "zod";

export const Schema = z.object({
  code: z.coerce
    .string({ required_error: translate("error_validation_required") })
    .regex(/\d+/, translate("error_validation_required"))
    .length(6)
});

const ConfirmEmailForm: FC<{
  errorCode: string;
  onSubmit: (data: VerifyEmailFormValues) => Promise<unknown>;
  newEmail: string;
  resendEmail: () => unknown;
  onChange?: () => void;
}> = (props) => {
  const [showResendLink, setShowResendLink] = React.useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowResendLink(true);
    }, 20000);
    return () => clearTimeout(timer);
  }, [showResendLink]);

  const handleResend = (): void => {
    props.resendEmail();
    setShowResendLink(false);
  };

  return (
    <>
      <nine-heading>
        <h5>
          <Translate msg="verification_code_title" />
        </h5>
        <p>
          <Translate msg="verification.code" />{" "}
          <strong>{props.newEmail}</strong>
        </p>
        {showResendLink && (
          <div>
            <Link
              className="color-c-80"
              style={{ fontWeight: 400 }}
              to="#"
              onClick={handleResend}
            >
              <Translate msg="resend_code" />
            </Link>
          </div>
        )}
      </nine-heading>

      <nine-spacer s="md"></nine-spacer>
      <AutoForm
        schema={Schema}
        onChange={() => {
          props.onChange?.();
        }}
        onSubmit={(data) => void props.onSubmit({ code: data.code })}
      >
        <AutoFormInputField
          name="code"
          label={translate("code")}
          isRequired={true}
          type="tel"
          mask={{
            lazy: true,
            mask: "000000"
          }}
        />
        <nine-spacer s="sm"></nine-spacer>
        <FormError code={props.errorCode} />
        <Button type="submit" theme="sunrise" style={{ margin: "auto" }}>
          <Translate msg="email" variables={{ context: "confirm" }} />
        </Button>
      </AutoForm>
    </>
  );
};

export default ConfirmEmailForm;
