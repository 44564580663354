import type { FC } from "react";
import React, { useCallback, useMemo, useState } from "react";
import { cleanEmail } from "src/lib/cleanEmail";
import { extractErrorCode } from "src/lib/errors";
import { TrackEvent, TrackType } from "src/state/Track/TrackCubit";
import AuthenticationBloc from "src/state/UserCubit/AuthenticationBloc";
import UserEmailVerificationCubit from "src/state/UserEmailVerificationCubit/UserEmailVerificationCubit";
import { toast, useBloc } from "src/state/state";
import ConfirmEmailForm from "src/ui/components/ChangeEmail/ConfirmEmailForm";
import EmailPasswordForm from "src/ui/components/ChangeEmail/EmailPasswordForm";
import SuccessChangedEmail from "src/ui/components/ChangeEmail/SuccessChangedEmail";
import Loader from "src/ui/components/Loader/Loader";
import Track from "src/ui/components/Track/Track";

export interface ChangeEmailFormValues {
  email: string;
  password: string;
}

export interface VerifyEmailFormValues {
  code: string;
}

const ChangeEmail: FC = () => {
  const bloc = useMemo(() => new UserEmailVerificationCubit(), []);
  const [errorCode, setErrorCode] = useState("");
  const [token, setToken] = useState("");
  const [formValues, setFormValues] = useState<ChangeEmailFormValues | null>(
    null
  );
  const [completed, setCompleted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { verifyEmailStateless } = bloc;

  const [, { changeEmail }] = useBloc(AuthenticationBloc, {
    subscribe: false
  });

  const handleChange = useCallback(() => {
    setErrorCode("");
  }, []);

  const requestChange = async (data?: ChangeEmailFormValues): Promise<void> => {
    if (!data) {
      setErrorCode("reset_password_failed");
      return;
    }

    setErrorCode("");

    setLoading(true);
    try {
      const { verificationToken } = await changeEmail({
        newEmail: cleanEmail(data.email),
        currentPassword: data.password
      });
      setToken(verificationToken);
    } catch (e) {
      const errCode = extractErrorCode(e);
      setErrorCode(errCode);
    }
    setLoading(false);
  };

  const handleRequestChange = async (
    data: ChangeEmailFormValues
  ): Promise<void> => {
    setFormValues(data);
    return requestChange(data);
  };

  const handleVerify = async (
    data: VerifyEmailFormValues
  ): Promise<boolean> => {
    if (formValues === null) {
      return false;
    }

    setLoading(true);
    const { error } = await verifyEmailStateless({
      email: cleanEmail(formValues.email),
      verificationToken: token,
      verificationCode: data.code
    });
    setErrorCode(error);
    if (!error) {
      setCompleted(true);
    }
    setLoading(false);
    return !error;
  };

  const handleResendEmail = useCallback(async () => {
    if (!formValues) return;
    await requestChange(formValues);
    toast.show("email_verification_sent");
  }, [formValues]);

  return (
    <>
      <Track event={TrackEvent.changeEmail} type={TrackType.start} />

      <Loader active={loading}>
        <div>
          {!token && (
            <EmailPasswordForm
              errorCode={errorCode}
              onSubmit={handleRequestChange}
              onChange={handleChange}
            />
          )}
          {token && formValues && (
            <ConfirmEmailForm
              errorCode={errorCode}
              resendEmail={handleResendEmail}
              onSubmit={handleVerify}
              onChange={handleChange}
              newEmail={formValues.email}
            />
          )}
          {completed && <SuccessChangedEmail />}
        </div>
      </Loader>
    </>
  );
};

export default ChangeEmail;
